<template>
<div class="modal-card">
    <section class="modal-card-body">
        <b-field>
            <h1 class="title">{{ $t('forgotPassword.header') }}</h1>
        </b-field>

        <form v-on:submit.prevent="preventRefresh">
            <b-field :label="email ? $t('email') + ' *' : ''" label-position='on-border'>
                <b-input type="email" ref="emailInput" v-model="email" :placeholder="$t('email') + ' *'" required :disabled="!isInitState">
                </b-input>
            </b-field>

            <b-field v-if="!isInitState" :label="code ? $t('code') + ' *' : ''" label-position='on-border'>
                <b-input v-model="code" type="number" ref="codeInput" :placeholder="$t('code') + ' *'" required>
                </b-input>
            </b-field>

            <b-field v-if="!isInitState" :label="password ? $t('password') + ' *' : ''" label-position='on-border'>
                <b-input type="password" v-model="password" password-reveal :placeholder="$t('password') + ' *'" required>
                </b-input>
            </b-field>

            <b-field v-if="!isInitState" :label="passwordRepeat ? $t('passwordRepeat') + ' *' : ''" label-position='on-border'>
                <b-input type="password" v-model="passwordRepeat" password-reveal :placeholder="$t('passwordRepeat') + ' *'" required>
                </b-input>
            </b-field>
            <nav class="level">
                <div class="level-item">
                    <b-field grouped>
                        <b-field>
                            <b-button :label="submitButtonText" native-type="submit" type="is-primary" v-on:click="submit" />
                        </b-field>
                        <b-field v-if="isModal">
                            <b-button :label="$t('interaction.close')" @click="$emit('close')" />
                        </b-field>
                    </b-field>
                </div>
            </nav>
            <nav class="level">
                <div class="level-item">
                    <b-field>
                        <a @click="changeToSignIn">{{ $t('backToSignIn') }}</a>
                    </b-field>
                </div>
            </nav>
        </form>
    </section>
</div>
</template>

<script>
import {
    Auth
} from 'aws-amplify';

import {
    AuthState
} from '@aws-amplify/ui-components';

import {
    dispatchAuthStateChangeEvent
} from '@/lib/auth-helpers';

const MIN_PASSWORD_LENGTH = 8

const STATES = {
    Init: 0,
    ReceivedCode: 1
}

export default {
    props: {
        'isModal': {
            type: Boolean,
            default: false,
        }
    },
    mounted() {
        this.state == STATES.Init ? this.$refs.emailInput.focus() : this.$refs.codeInput.focus();
    },
    data() {
        return {
            email: "",
            password: "",
            passwordRepeat: "",
            code: "",
            state: STATES.Init

        }
    },
    computed: {
        isInitState() {
            return this.state == STATES.Init;
        },
        submitButtonText() {
            return this.isInitState ? this.$t('sendCode') : this.$t('forgotPassword.changePassword');
        }
    },
    methods: {
        preventRefresh() {
            return;
        },
        changeToSignIn() {
            dispatchAuthStateChangeEvent(AuthState.SignIn);
        },
        async requestCode() {
            await Auth.forgotPassword(this.email.trim());
            this.state = STATES.ReceivedCode;
        },
        async changePassword() {
            await Auth.forgotPasswordSubmit(this.email, this.code, this.password);
            this.changeToSignIn();
            this.state = STATES.Init;
        },
        async submit() {
            let messages = this.checkInput();
            if (messages.length) {
                this.alertError(messages.join('<br>'));
                return;
            }

            const requestFunction = this.state == STATES.Init ? this.requestCode : this.changePassword;

            const loadingComponent = this.$buefy.loading.open({
                container: null
            });
            try {
                await requestFunction();
            } catch (error) {
                this.alertError(error.message);
            } finally {
                loadingComponent.close();
            }
        },
        checkInput() {
            let messages = []
            if (!this.email || !/^\S+@\S+\.\S+$/.test(this.email)) {
                messages.push(this.$t('notifications.emailNotValid'));
            }

            if (this.state == STATES.ReceivedCode) {
                if (!this.code.length) {
                    messages.push(this.$t('notifications.codeNotValid'));
                }
                if (this.password.length < MIN_PASSWORD_LENGTH) {
                    messages.push(this.$t('notifications.passwordNotValid'));
                } else if (this.password != this.passwordRepeat) {
                    messages.push(this.$t('notifications.passwordsNotMatching'));
                }
            }

            return messages;
        },
        alertError(errorMessage) {
            this.$buefy.notification.open({
                duration: 3500,
                message: errorMessage,
                position: 'is-top',
                type: 'is-danger',
                hasIcon: true
            })
        }
    }
}
</script>
