// Auto generated constants
export default {BUSINESS_USER_ID_LENGTH: 7,
RELATIVE_LOCATION_ID_LENGTH: 3,
RELATIVE_PROVIDER_ID_LENGTH: 2,
APPOINTMENT_TYPE_ID_LENGTH: 1,
APPOINTMENT_EXTENDED_ID_LENGTH: 1,
MAX_NUMBER_OF_LOCATIONS: 5,
MAX_NUMBER_OF_LOCATIONS_PER_PROVIDER: 3,
MAX_TITLE_LENGTH: 35,
MIN_FIRST_LAST_NAME_LENGTH: 2,
MAX_FIRST_NAME_LENGTH: 25,
MAX_LAST_NAME_LENGTH: 30,
MIN_PHONE_NUMBER_LENGTH: 8,
MAX_PHONE_NUMBER_LENGTH: 17,
MAX_LOCATION_NAME_LENGTH: 128,
MAX_PROVIDER_DISPLAY_NAME_LENGTH: 64,
MAX_STREET_LENGTH: 256,
MAX_ZIP_CODE_LENGTH: 10,
MAX_CUSTOMER_MESSAGE_LENGTH: 160,
APPOINTMENT_VERIFICATION_CODE_LENGTH: 6,
MIN_APPOINTMENT_DURATION: 5,
MAX_APPOINTMENT_DURATION: 420,
APPOINTMENT_DURATION_STEP: 5,
MAX_OPENING_HOUR_SECTIONS_PER_DAY: 8,
MAX_NUMBER_OF_APPOINTMENT_TYPES: 32,
MAX_APPOINTMENT_TYPE_NAME_LENGTH: 100,
RESEND_TIMER_TIMEOUT: 90,
SEARCH_RESULT_PAGINATION_SIZE: 2,
PUBLIC_QUERY_SEARCH_CHUNK_SIZE: 15,
MAX_SEARCH_RESULTS: 30,
MAX_AGE: 120,
AppointmentFormats:{
    kOnSiteOnly: "0",
    kVideoOnly: "1",
    kAny: "2"},
Salutations:{
    kMr: "0",
    kMrs: "1",
    kAny: "2"},
InsuranceTypes:{
    kStatutory: "0",
    kPrivate: "1",
    kAny: "2"},
CURRENCIES:{
    U: "USD",
    E: "EUR",
    J: "JPY",
    G: "GBP",
    A: "AUD",
    C: "CAD",
    F: "CHF",
    Y: "CNY",
    H: "HKD",
    N: "NZD",
    S: "SEK",
    W: "KRW",
    s: "SGD",
    K: "NOK",
    P: "MXN",
    I: "INR",
    R: "RUB",
    z: "ZAR",
    T: "TRY",
    B: "BRL",
    Q: "TWD",
    D: "DKK",
    Z: "PLN",
    x: "THB",
    O: "IDR",
    h: "HUF",
    c: "CZK",
    l: "ILS",
    p: "CLP",
    o: "PHP",
    X: "AED",
    r: "SAR",
    M: "MYR",
    L: "RON"}}