<template>
<div class="modal-card">
    <section class="modal-card-body">
        <b-field>
            <h1 class="title">{{ uiText.header }}</h1>
        </b-field>
        <b-field v-if="uiText.explanation">
            {{ uiText.explanation }}
        </b-field>
        <form v-on:submit.prevent="preventRefresh">
            <b-field :label="uiText.inputLabel">
                <b-input ref="input" v-model="input" :has-counter="false" autocomplete="nope"></b-input>
            </b-field>
            <b-field v-if="type === 'confirm-sign-up'">
                {{ $t('interaction.didntReceiveCode') }}
                <a v-if="resendTimer == 0" @click="resendCode">{{ $t('interaction.resendCode') }}</a>
                <span v-if="resendTimer > 0">{{ $t('interaction.waitForCodeWithTimer', { timer: formattedResendTimer }) }}</span>
            </b-field>
            <nav class="level">
                <div class="level-item">
                    <b-field grouped>
                        <b-field>
                            <b-button :label="uiText.buttonText" native-type="submit" type="is-primary" v-on:click="submit" />
                        </b-field>
                        <b-field v-if="isModal">
                            <b-button :label="$t('interaction.cancel')" @click="$emit('close')" />
                        </b-field>
                    </b-field>
                </div>
            </nav>
            <nav class="level">
                <div class="level-item">
                    <b-field>
                        <a @click="backToSignIn">{{ $t('backToSignIn') }}</a>
                    </b-field>
                </div>
            </nav>
        </form>
    </section>
</div>
</template>

<script>
import {
    Auth
} from "aws-amplify";

import {
    AuthState,
    ChallengeName
} from "@aws-amplify/ui-components";

import {
    handleSignIn,
    dispatchAuthStateChangeEvent,
    checkContact,
} from "@/lib/auth-helpers";

export default {
    props: {
        user: {
            type: Object,
        },
        type: {
            type: String,
        },
        formType: {
            type: String,
        },
        isModal: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            input: undefined,
            resendTimer: this.$CONSTANTS.RESEND_TIMER_TIMEOUT
        };
    },
    mounted() {
        this.countDownTimer();
    },
    computed: {
        uiText() {
            return this.UI_TEXT_MAP[this.type];
        },
        formattedResendTimer() {
            if (this.resendTimer > 0)
                return (
                    Math.floor(this.resendTimer / 60).toString() +
                    ":" +
                    (this.resendTimer % 60).toString().padStart(2, "0")
                );
            return "";
        },
        UI_TEXT_MAP() {
            return {
                "confirm-sign-in": {
                    header: this.$t('confirmSignIn.header'),
                    inputLabel: this.$t('confirmationCode') + ' *',
                    buttonText: this.$t('confirm'),
                    explanation: this.$t('confirmSignIn.explanation')
                },
                "confirm-sign-up": {
                    header: this.$t('confirmSignUp.header'),
                    inputLabel: this.$t('confirmationCode') + ' *',
                    buttonText: this.$t('confirm'),
                    explanation: this.$t('confirmSignUp.explanation')
                }
            }
        }
    },
    methods: {
        preventRefresh() {
            return;
        },
        countDownTimer() {
            if (this.resendTimer > 0) {
                setTimeout(() => {
                    this.resendTimer -= 1;
                    this.countDownTimer();
                }, 1000);
            }
        },
        async resendCode() {
            if (!this.user) {
                this.alertError(this.$t('notifications.usernameNotValid'));
                return;
            }
            this.resendTimer = this.$CONSTANTS.RESEND_TIMER_TIMEOUT;
            this.countDownTimer();
            try {
                await Auth.resendSignUp(this.user.username.trim());
            } catch (error) {
                this.alertError(error.message);
            }
        },
        async confirmSignUp() {
            this.loading = true;
            try {
                let username = this.user.username.trim();
                if (!username) throw new Error(this.$t('notifications.usernameNotValid'));
                const confirmSignUpResult = await Auth.confirmSignUp(
                    username,
                    this.input
                );
                if (!confirmSignUpResult) {
                    throw new Error(this.$t('notifications.confirmSignUpFailed'));
                }
                const signUpAttrs = this.user && this.user.signUpAttrs;
                if (
                    signUpAttrs &&
                    signUpAttrs.password &&
                    signUpAttrs.password !== ""
                ) {
                    // Auto sign in user if password is available from previous workflow
                    await handleSignIn(this.user.username, signUpAttrs.password);
                } else {
                    dispatchAuthStateChangeEvent(AuthState.SignIn);
                }
            } catch (error) {
                this.alertError(error.message);
            } finally {
                this.loading = false;
            }
        },
        async confirmSignIn() {
            const mfaType =
                this.user["challengeName"] === ChallengeName.SoftwareTokenMFA ?
                ChallengeName.SoftwareTokenMFA :
                null;
            this.loading = true;
            try {
                await Auth.confirmSignIn(this.user, this.input, mfaType);
                await checkContact(this.user, undefined);
            } catch (error) {
                this.alertError(error.message);
            } finally {
                this.loading = false;
            }
        },
        submit() {
            switch (this.type) {
                case "confirm-sign-in":
                    this.confirmSignIn();
                    break;
                case "confirm-sign-up":
                    this.confirmSignUp();
                    break;
                default:
                    break;
            }
        },
        backToSignIn() {
            dispatchAuthStateChangeEvent(AuthState.SignIn);
        },
        alertError(errorMessage) {
            this.$buefy.notification.open({
                duration: 3500,
                message: errorMessage,
                position: "is-top",
                type: "is-danger",
                hasIcon: true,
            });
        },
    },
};
</script>
