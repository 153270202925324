<template>
    <div class="container is-flex is-justify-content-center is-align-items-center" style="margin-top: 250px;">
        Hello world
    </div>
</template>

<script>
import {
    app,
    authentication
} from "@microsoft/teams-js";
export default {
    mounted() {
        console.log("Initializing");
        app.initialize().then(() => {
            console.log("Initialized");
            authentication.getAuthToken({
                resources: this.appIdURI,
                silent: false,
            })
                .then((token) => {
                    console.log("Got token");
                    this.token = token;
                    console.log(token);
                }).catch((error) => console.log(error));
            app.getContext().then(r => {
                console.log("Got context");
                console.log(r);
            }).catch((error) => console.log(error));
        }).catch((error) => console.log(error))
    }
}
</script>
