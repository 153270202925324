import Vue from 'vue'
import Vuex from 'vuex'

import { IOpeningHour } from "../types/index";

export interface AppointmentType {
  id: string;
  name: string;
  locations: any[];
  duration: number;
  format: string;
  dayFilter: any[];
  hourFilter: any[];
  minuteFilter: any[];
  color: string;
  price?: any;
  currency?: any;
}

Vue.use(Vuex)

/*const appointmentTypesOld = [
  {
    "id": 1,
    "type": "Haircut",
    "length": 30,
    "color": "red",
  },
  {
    "id": 2,
    "type": "Facial Hair",
    "length": 30,
    "color": "green"
  },
  {
    "id": 3,
    "type": "Hairwash",
    "length": 30,
    "color": "blue"
  }
]*/

/*const appointmentTypes = {
  "Haircut": {
    "length": 30,
    "color": "red",
  },
  "Facial Hair": {
    "length": 30,
    "color": "green"
  },
  "Hairwash": {
    "length": 30,
    "color": "blue"
  }
}*/

enum BREAKPOINTS {
  mobile = 0,
  tablet = 769,
  desktop = 1024,
  widescreen = 1216,
  fullhd = 1408
}

export default new Vuex.Store({
  state: {
    breakpoints: BREAKPOINTS,
    windowWidth: 0,
    timezones: [Intl.DateTimeFormat().resolvedOptions().timeZone],
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    userSignedIn: false,
    personalData: null,
    accountData: null,
    providers: null,
    businessData: [],
    personalDataRemote: null,
    accountDataRemote: null,
    businessDataRemote: null,
    providersRemote: null,
    openingHours: { 0: [], 1: [], 2: [], 3: [], 4: [], 5: [], 6: [] },
    appointmentTypes: [] as Array<AppointmentType>, //<AppointmentType[]>[],
    appointmentTypesRemote: null,
    appointmentTypesAsString: "",
    offDays: [],
    showWeekend: false,
    calendarCellSizeFactor: 1.5,
    searchState: 'INIT',
    searchInput: {},
    searchResults: [],
    lastSearchResultsLength: 0,
    tz_offsets: {},
    searchTime: 0,
    appointmentRequest: null,
    requestedSlot: null,
    // TIME_ZONES: ["UTC−12:00", "UTC−11:00", "UTC−10:00", "UTC−09:30", "UTC−09:00", "UTC−08:00", "UTC−07:00", "UTC−06:00", "UTC−05:00", "UTC−04:00", "UTC−03:30", "UTC−03:00", "UTC−02:00", "UTC−01:00", "UTC±00:00", "UTC+01:00", "UTC+02:00", "UTC+03:00", "UTC+03:30", "UTC+04:00", "UTC+04:30", "UTC+05:00", "UTC+05:30", "UTC+05:45", "UTC+06:00", "UTC+06:30", "UTC+07:00", "UTC+08:00", "UTC+08:45", "UTC+09:00", "UTC+09:30", "UTC+10:00", "UTC+10:30", "UTC+11:00", "UTC+12:00", "UTC+12:45", "UTC+13:00", "UTC+14:00"],
    COUNTRIES: null,
    OCCUPATIONS: null,
    CODE_OCCUPATION_LUT: {},
    LOCATION_MAJORS: null,
    LOCATION_MINORS: null,
    PROVIDER_MAJORS: null,
    PROVIDER_MINORS: null,
    ALL_MAJORS: null,
    ALL_MINORS: null,
    CODE_BUSINESS_LUT: null,
    TIME_ZONES: {'-12': 'UTC−12:00', '-11': 'UTC−11:00', '-10': 'UTC−10:00', '-9.5': 'UTC−09:30', '-9': 'UTC−09:00', '-8': 'UTC−08:00', '-7': 'UTC−07:00', '-6': 'UTC−06:00', '-5': 'UTC−05:00', '-4': 'UTC−04:00', '-3.5': 'UTC−03:30', '-3': 'UTC−03:00', '-2': 'UTC−02:00', '-1': 'UTC−01:00', '0': 'UTC±00:00', '1': 'UTC+01:00', '2': 'UTC+02:00', '3': 'UTC+03:00', '3.5': 'UTC+03:30', '4': 'UTC+04:00', '4.5': 'UTC+04:30', '5': 'UTC+05:00', '5.5': 'UTC+05:30', '5.75': 'UTC+05:45', '6': 'UTC+06:00', '6.5': 'UTC+06:30', '7': 'UTC+07:00', '8': 'UTC+08:00', '8.75': 'UTC+08:45', '9': 'UTC+09:00', '9.5': 'UTC+09:30', '10': 'UTC+10:00', '10.5': 'UTC+10:30', '11': 'UTC+11:00', '12': 'UTC+12:00', '12.75': 'UTC+12:45', '13': 'UTC+13:00', '14': 'UTC+14:00'},
    HOURS: [...Array(24).keys()].map(i => (i).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })),
    MINUTES: [...Array(12).keys()].map(i => (i * 5).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })),
    CURRENCIES: ["EUR", "GBP", "USD", "TRY"],
    CURRENCY_CODES: {'U': 'USD', 'E': 'EUR', 'J': 'JPY', 'G': 'GBP', 'A': 'AUD', 'C': 'CAD', 'F': 'CHF', 'Y': 'CNY', 'H': 'HKD', 'N': 'NZD', 'S': 'SEK', 'W': 'KRW', 's': 'SGD', 'K': 'NOK', 'P': 'MXN', 'I': 'INR', 'R': 'RUB', 'z': 'ZAR', 'T': 'TRY', 'B': 'BRL', 'Q': 'TWD', 'D': 'DKK', 'Z': 'PLN', 'x': 'THB', 'O': 'IDR', 'h': 'HUF', 'c': 'CZK', 'l': 'ILS', 'p': 'CLP', 'o': 'PHP', 'X': 'AED', 'r': 'SAR', 'M': 'MYR', 'L': 'RON'},
    DEFAULT_CURRENCY: "E",
    LOCALES: {'en-GB': 'English', 'de-DE': 'Deutsch', 'tr-TR': 'Türkçe'},
  },
  mutations: {
    addAppointmentType(state, payload) {
      state.appointmentTypes.push(payload);
    },

    updateAppointmentTypeAt(state, payload) {
      state.appointmentTypes[payload.idx] = payload.data;
      state.appointmentTypesAsString = JSON.stringify(state.appointmentTypes);
    },

    deleteAppointmentType(state, keysToDelete) {
      state.appointmentTypes = state.appointmentTypes.filter(function (appointmentType) {
        return !keysToDelete.includes(appointmentType.id);
      });
    },

    updateOffDays(state, days) {
      state.offDays = days;
    },

    setSearchResults(state, data){
      state.searchResults = data.providers;
      state.tz_offsets = data.offsets;
      state.searchTime = new Date().getTime();
    },

    setOccupationCodes(state, data){
      state.OCCUPATIONS = data;
    },

    setCodeOccupationLUT(state, data){
      state.CODE_OCCUPATION_LUT = data;
    },

    setSearchInput(state, data){
      state.searchInput = data;
    },

    setAppointmentRequest(state, data){
      state.appointmentRequest = data;
    },

    setRequestedSlot(state, data){
      state.requestedSlot = data;
    }
  },
  getters: {
     deviceType: state => {
      let breakpoint = '';
      for(const bp in BREAKPOINTS){
        if(state.windowWidth < parseInt(BREAKPOINTS[bp]))
          break;
        breakpoint = bp;
      }
      return breakpoint
    }
  },
  actions: {
  },
  modules: {
  }
})
