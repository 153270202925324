/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_cognito_identity_pool_id": "eu-central-1:2c13896e-464a-4bf5-9621-2d2d132a4bd8",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_NSgH3mZBr",
    "aws_user_pools_web_client_id": "486g5c88leho26s3gc5ipro5l",
    "oauth": {}
};


export default awsmobile;
