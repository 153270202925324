import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import i18n from './vue-i18n';

import constants from './constants_gen';
Vue.prototype.$CONSTANTS = constants;

import '@aws-amplify/ui-vue';
import Amplify from 'aws-amplify';
import awsconfig from './aws-exports';

Amplify.configure(awsconfig);

import '@fortawesome/fontawesome-free/css/all.min.css';

require('@/assets/main.scss');

import Buefy from 'buefy'
// import 'buefy/dist/buefy.css'

import { library } from '@fortawesome/fontawesome-svg-core';
// internal icons
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(fas);
Vue.component('vue-fontawesome', FontAwesomeIcon);

Vue.use(Buefy, {
  defaultIconComponent: "vue-fontawesome",
  defaultIconPack: "fa",
  customIconPacks: {
    fa: {
      sizes: {
        default: "lg",
        "is-small": "1x",
        "is-medium": "2x",
        "is-large": "3x"
      },
      iconPrefix: ""
    }
  }
});

import Authenticator from '@/components/Authenticator.vue';
Vue.component('authenticator', Authenticator);

Vue.config.productionTip = false

/*router.beforeEach((to, _from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.state.userSignedIn) {
      next({
        path: '/register',
        query: { redirectOnSignIn: to.fullPath, action: 'signin' }
      })
    } else {
      next()
    }
  } else {
    next()
  }
})*/

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app');