<template>
<div class="modal-card">
    <section class="modal-card-body">
        <b-field>
            <h1 class="title">{{ signTypeText }}</h1>
        </b-field>

        <form v-on:submit.prevent="preventRefresh">
            <b-field :label="email ? $t('email') + ' *' : ''" label-position='on-border'>
                <b-input type="email" ref="emailInput" v-model="email" :placeholder="$t('email') + ' *'" required>
                </b-input>
            </b-field>

            <b-field :label="password ? $t('password') + ' *' : ''" label-position='on-border'>
                <b-input type="password" v-model="password" password-reveal :placeholder="$t('password') + ' *'" required>
                </b-input>
            </b-field>
            <b-field :label="passwordRepeat ? $t('passwordRepeat') + ' *' : ''" label-position='on-border' v-if="!isSignInForm">
                <b-input type="password" v-model="passwordRepeat" password-reveal :placeholder="$t('passwordRepeat') + ' *'" required>
                </b-input>
            </b-field>
            <b-field :label="phoneNumber ? $t('phoneNumber') + ' *' : ''" label-position='on-border' v-if="!isSignInForm" >
                <b-input type="tel" v-model="phoneNumber" :placeholder="$t('phoneNumber') + ' *'" required>
                </b-input>
            </b-field>
            <b-field v-if="isSignInForm">
                <b-checkbox>{{ $t('rememberMe') }}</b-checkbox>
            </b-field>
            <b-field v-if="isSignInForm">
                <div>
                    <a @click="forgotPassword">{{ $t('interaction.forgotPassword') }}</a>
                </div>
            </b-field>
            <nav class="level">
                <div class="level-item">
                    <b-field grouped>
                        <b-field>
                            <b-button :label=signTypeText native-type="submit" type="is-primary" v-on:click="submit" />
                        </b-field>
                        <b-field v-if="isModal">
                            <b-button :label="$t('interaction.close')" @click="$emit('close')" />
                        </b-field>
                    </b-field>
                </div>
            </nav>
            <nav class="level">
                <div class="level-item">
                    <b-field v-if="isSignInForm">
                        {{ $t('interaction.noAccountYet') }} <a @click="changeAuthState">{{ $t('interaction.createAccountNow') }}</a>
                    </b-field>
                    <b-field v-else>
                        {{ $t('interaction.alreadyRegistered') }} <a @click="changeAuthState">{{ $t('signIn') }}</a>
                    </b-field>
                </div>
            </nav>
        </form>
    </section>
</div>
</template>

<script>
import {
    Auth
} from 'aws-amplify';

import {
    AuthState
} from '@aws-amplify/ui-components';

import {
    handleSignIn, dispatchAuthStateChangeEvent
} from '@/lib/auth-helpers';

const MIN_PASSWORD_LENGTH = 8

export default {
    props: {
        'isModal': {
            type: Boolean,
            default: false,
        },
        'type': {
            type: String
        }
    },
    mounted() {
        this.$refs.emailInput.focus();
    },
    data() {
        return {
            email: "",
            password: "",
            passwordRepeat: "",
            phoneNumber: "",
            loadingComponent: null,
            isFullPage: true,
            usernameAlias: 'email'
        }
    },
    computed: {
        isSignInForm() {
            return this.type == 'sign-in'
        },
        signTypeText() {
            return this.isSignInForm ? this.$t('signIn') : this.$t('signUp');
        }
    },
    methods: {
        preventRefresh() {
            return;
        },
        forgotPassword() {
            dispatchAuthStateChangeEvent(AuthState.ForgotPassword);
        },
        changeAuthState() {
            if (this.isSignInForm)
                dispatchAuthStateChangeEvent(AuthState.SignUp);
            else
                dispatchAuthStateChangeEvent(AuthState.SignIn);
        },
        submit() {
            let messages = this.checkInput();
            if (messages.length) {
                this.alertError(messages.join('<br>'));
                return;
            }

            if (this.isSignInForm)
                this.signIn();
            else
                this.signUp();
        },
        async handleSignUp(params) {
            const data = await Auth.signUp(params);
            if (!data) {
                throw new Error(this.$t('notifications.signUpFailed'));
            }
            return data;
            /*dispatchAuthStateChangeEvent(AuthState.ConfirmSignUp, {
                username: this.email
            });*/
        },
        async signUp() {
            //this.loading = true;
            //this.assignFormInputToSignUpAttributes();
            try {
                //this.validateSignUpAttributes();
                const signUpParams = {
                    username: this.email,
                    password: this.password,
                    attributes: {
                        email: this.email,
                        phone_number: this.phoneNumber,
                    }
                }
                const data = await this.handleSignUp(signUpParams);
                if (data.userConfirmed) {
                    await handleSignIn(this.email, this.password);
                } else {
                    const signUpAttrs = Object.assign({}, signUpParams);
                    dispatchAuthStateChangeEvent(AuthState.ConfirmSignUp, Object.assign(Object.assign({}, data.user), {
                        signUpAttrs
                    }));
                }
            } catch (error) {
                this.alertError(error.message);
            } finally {
                this.loading = false;
            }
        },
        async signIn() {
            const loadingComponent = this.$buefy.loading.open({
                container: this.isFullPage ? null : this.$refs.element.$el
            })
            try {
                await handleSignIn(this.email.trim(), this.password, undefined, this.usernameAlias);
                if (this.isModal)
                    this.$emit('close');
                this.$buefy.toast.open({
                    message: this.$t('notifications.signInSuccess'),
                    type: 'is-success',
                    pauseOnHover: true,
                    queue: false
                })
            } catch (error) {
                //let msg = error.code && error.code == 'NotAuthorizedException' ? 'Incorrect username or password' : 'Failed to sign in.<br>Make sure you are connected to the internet.';
                //this.alertError(msg);
                this.alertError(error.message);
            }
            loadingComponent.close();
        },
        checkInput() {
            let messages = []
            if (!this.email || !/^\S+@\S+\.\S+$/.test(this.email)) {
                messages.push(this.$t('notifications.emailNotValid'));
            }

            if (this.password.length < MIN_PASSWORD_LENGTH) {
                messages.push(this.$t('notifications.passwordNotValid'));
            } else if (this.password != this.passwordRepeat && !this.isSignInForm) {
                messages.push(this.$t('notifications.passwordsNotMatching'));
            }

            if (!this.phoneNumber.length && !this.isSignInForm) {
                messages.push(this.$t('notifications.phoneNumberNotValid'));
            }

            return messages;
        },
        alertError(errorMessage) {
            this.$buefy.notification.open({
                duration: 3500,
                message: errorMessage,
                position: 'is-top',
                type: 'is-danger',
                hasIcon: true
            })
        }
    }
}
</script>
