import { Auth, CognitoUser } from '@aws-amplify/auth';

import {
    AuthState,
    ChallengeName,
    UsernameAlias
} from '@aws-amplify/ui-components';

import { AuthErrorStrings } from '@aws-amplify/auth';

import { isEmpty } from '@aws-amplify/core';

import { Hub } from '@aws-amplify/core';

// Copied from @aws-amplify/ui-components/dist/collection/common/constants.ts
const UI_AUTH_CHANNEL = 'UI Auth';
const AUTH_STATE_CHANGE_EVENT = 'AuthStateChange';

// Copied from @aws-amplify/ui-components/dist/collection/common/helpers.js
export const dispatchAuthStateChangeEvent = (nextAuthState, data) => {
    Hub.dispatch(UI_AUTH_CHANNEL, {
        event: AUTH_STATE_CHANGE_EVENT,
        message: nextAuthState,
        data,
    });
};

export async function checkContact(user, _handleAuthStateChange) {
    if (!isCognitoUser(user)) {
        dispatchAuthStateChangeEvent(AuthState.SignedIn, user);
        return;
    }
    const data = await Auth.verifiedContact(user);
    if (!isEmpty(data.verified) || isEmpty(data.unverified)) {
    //if (isEmpty(data.unverified)) {   // Verify everything
        dispatchAuthStateChangeEvent(AuthState.SignedIn, user);
    }
    else {
        const newUser = Object.assign(user, data);
        dispatchAuthStateChangeEvent(AuthState.VerifyContact, newUser);
    }
}
export const handleSignIn = async (username, password, _handleAuthStateChange, usernameAlias) => {
    try {
        const user = await Auth.signIn(username, password);
        if (user.challengeName === ChallengeName.SMSMFA ||
            user.challengeName === ChallengeName.SoftwareTokenMFA) {
            dispatchAuthStateChangeEvent(AuthState.ConfirmSignIn, user);
        }
        else if (user.challengeName === ChallengeName.NewPasswordRequired) {
            dispatchAuthStateChangeEvent(AuthState.ResetPassword, user);
        }
        else if (user.challengeName === ChallengeName.MFASetup) {
            dispatchAuthStateChangeEvent(AuthState.TOTPSetup, user);
        }
        else if (user.challengeName === ChallengeName.CustomChallenge &&
            user.challengeParam &&
            user.challengeParam.trigger === 'true') {
            dispatchAuthStateChangeEvent(AuthState.CustomConfirmSignIn, user);
        }
        else {
            await checkContact(user, _handleAuthStateChange);
        }
    }
    catch (error) {
        if (error.code === 'UserNotConfirmedException') {
            dispatchAuthStateChangeEvent(AuthState.ConfirmSignUp, { username });
        }
        else if (error.code === 'PasswordResetRequiredException') {
            dispatchAuthStateChangeEvent(AuthState.ForgotPassword, { username });
        }
        else if (error.code === 'InvalidParameterException' && password === '') {
            error.message = AuthErrorStrings.EMPTY_PASSWORD;
        }
        else if (error.message === AuthErrorStrings.EMPTY_USERNAME) {
            if (usernameAlias === UsernameAlias.email) {
                error.message = 'Email cannot be empty';
            }
            if (usernameAlias === UsernameAlias.phone_number) {
                error.message = 'Phone number cannot be empty';
            }
        }
        throw error;
    }
};
export const isCognitoUser = (user) => {
    return user instanceof CognitoUser;
};