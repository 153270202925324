<template>
<div>
    <amplify-authenticator username-alias="email" :initial-auth-state="initialAuthState">
        <confirm-form slot="confirm-sign-up" type="confirm-sign-up" :user="user" />
        <confirm-form slot="confirm-sign-in" type="confirm-sign-in" :user="user" />
        <forgot-password v-if="forgotPassword" slot="forgot-password" type="forgot-password" />
        <login-form slot="sign-up" type="sign-up" username-alias="email" />
        <login-form slot="sign-in" type="sign-in" username-alias="email" />
        <slot></slot>
    </amplify-authenticator>
</div>
</template>

<script>
import {
    AuthState,
    onAuthUIStateChange
} from '@aws-amplify/ui-components';

import SignInOutForm from "@/components/authenticator_forms/SignInOutForm.vue";
import ConfirmForm from "@/components/authenticator_forms/ConfirmForm.vue";
import ForgotPassword from "@/components/authenticator_forms/ForgotPassword.vue";

export default {
    props: {
        'initialAuthState': {
            type: String,
            default: AuthState.SignIn
        },
        'isModal': {
            type: Boolean,
            default: false
        }
    },
    created() {
        this.unsubscribeAuth = onAuthUIStateChange((authState, authData) => {
            this.authState = authState;
            this.user = authData;
            if (this.isModal && this.authState == AuthState.SignedIn)
                this.$emit('close');
        });
    },
    components: {
        'login-form': SignInOutForm,
        'confirm-form': ConfirmForm,
        'forgot-password': ForgotPassword
    },
    computed: {
        forgotPassword() {
            return this.authState == AuthState.ForgotPassword;
        }
    },
    data() {
        return {
            user: undefined,
            authState: undefined,
            unsubscribeAuth: undefined
        };
    },
    beforeDestroy() {
        this.unsubscribeAuth();
    }
}
</script>

<style lang="scss">
:root {
    --amplify-primary-color: #2196f3;
    --amplify-primary-tint: #2196f3;
    --amplify-primary-shade: #ffffff;
    --amplify-background-color: #ffffff;
    --box-shadow: 0px 0px 0px 0 rgba(1, 1, 1, 0.15);
    --font-family: "Avenir", "Helvetica", "sans-serif";
    --padding: 0px 0px 0px 0px;
}
</style>
