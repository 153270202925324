import Vue from 'vue';
import VueI18n from 'vue-i18n';

const messages = {
  'en-GB': {
    account: 'Account',
    profile: 'Profile',
    calendar: 'Calendar',
    appointmentType: 'Appointment Type | Appointment Types',
    personalData: 'Personal Data',
    accountInformation: 'Account Information',
    userID: 'User ID',
    accountStatus: 'Account Status',
    salutation: 'Salutation',
    mr: 'Mr.',
    mrs: 'Mrs.',
    na: 'N/A',
    title: 'Title',
    lastName: 'Last Name',
    firstName: 'First Name',
    editOpeningHours: 'Edit Opening Hours',
    name: 'Name',
    usePersonalPhoneNumber: 'Use personal phone number',
    usePersonalEmail: 'Use personal email address',
    occupation_eventually_plural: 'Occupation(s)',
    specialization_eventually_plural: 'Specialization(s)',
    country: 'Country',
    city: 'City',
    streetAndHouseNumber: 'Street and House Number',
    zipCode: 'Zip Code',
    addNewLocation: 'Add new location',
    employee: 'Employee | Employees',
    addEmployee: 'Add employee',
    newEmployee: 'New employee',
    editEmployee: 'Edit employee information',
    deleteEmployee: 'Delete employee',
    noEmployees: 'No employees defined',
    employeeHasOwnAccess: 'Has own access (Requires e-mail verification)',
    selectableByCustomers: 'Can be selected by customers',
    appearsIndividuallyInSearchResults: 'Appears individually in search results',
    defineIndividualWorkingHours: 'Define individual working hours',
    doesTheEmployeeWorkAtAnotherLocation: 'Does this employee work at another location',
    displayName: 'Display Name',
    saveChanges: 'Save changes',
    search: 'Search',
    signIn: 'Sign in',
    signUp: 'Sign up',
    signOut: 'Sign out',
    email: 'Email address',
    repeatEmail: 'Repeat email address',
    password: 'Password',
    passwordRepeat: 'Repeat password',
    phoneNumber: 'Phone number',
    rememberMe: 'Remember me',
    confirmationCode: 'Confirmation Code',
    confirm: 'Confirm',
    sendCode: 'Send Code',
    backToSignIn: 'Back to Sign in',
    confirmSignIn: {
      header: 'Confirm Sign in',
      explanation: "Please enter the confirmation code we sent to you",
    },
    confirmSignUp: {
      header: "Confirm Sign up",
      explanation: "Please enter the confirmation code we sent to you",
    },
    forgotPassword: {
      header: "Reset password",
      explanation: "Please enter your email address below. We will send you a verification code, with which you can reset your password.",
      changePassword: "Change password"
    },
    code: "Code",
    appointmentTypeNamePlaceholder: 'Name of the appointment type',
    appointmentDuration: 'Appointment Duration',
    appointmentCanBeBooked: 'This appointment can only be booked during the following times',
    locationFilter: 'Location Filter',
    price: 'Price',
    eventColor: 'Event Color',
    eventColorTooltip: 'Optional: Color coded appointment types in the calendar view',
    editAppointmentTypeDetails: 'Edit appointment type details',
    appointmentFormat: 'Appointment format',
    onSite: 'On-site',
    video: 'Video',
    filterRules: 'Filter Rules for Appointment Time',
    removeAllFilters: 'Remove all filters',
    minutes: 'minutes',
    show: 'Show',
    hide: 'Hide',
    all: 'All',
    location: 'location | locations',
    selected: 'selected',
    today: 'Today',
    openingHours: 'Opening Hours',
    add: 'Add',
    closed: 'Closed',
    showWeekend: 'Show weekend',
    calendarSettings: 'Calendar settings',
    cellSize: 'Cell size',
    editAbsence: 'Edit absence',
    startHour: 'From',
    endHour: 'Until',
    appointmentTypeFilter: 'Appointment Type Filter',
    appointmentTypeFilterPlaceholder: 'Appointment type names',
    alwaysValid: 'Always valid',
    validBetween: 'Valid between',
    validFrom: 'Start date',
    validUntil: 'End date',
    appointmentDurationTooltip: 'Duration of each appointment slot within these opening hours. It must be a multiple of 5 minutes.',
    appointmentTypeFilterTooltip: 'Add specific appointment types which are allowed during these hours. Leave empty if you do not want to filter.',
    defineValidityRangeTooltip: 'Define validity data range for these opening hours.',
    removeOpeningHoursTooltip: 'Remove these opening hours slot.',
    newOpeningHoursSlot: 'New opening hours slot',
    appointmentDetails: 'Appointment Details',
    birthDate: 'Birth date',
    messageArea: 'Message',
    optionalMessageArea: 'Message (optional)',
    confirmAppointment: 'Confirm appointment',
    uploadNewProfilePicture: 'Upload new profile picture',
    removeProfilePicture: 'Remove profile picture',
    currentTimeZone: 'Current time zone',
    selectAppointmentType: 'Please select your appointment reason',
    selectedAppointment: 'Selected appointment',
    appointmentReason: 'Appointment reason',
    insuranceType: 'Health insurance',
    statutoryHealthInsurance: 'Statutory health insurance',
    privateHealthInsurance: 'Private health insurance',
    acceptedInsuranceTypes: 'Accepted insurance types',
    allInsuranceTypes: 'All insurance types',
    statutoryHealthInsuranceOnly: 'Statutory health insurance only',
    privateHealthInsuranceOnly: 'Private health insurance only',
    dateAndTime: 'Date and time',
    nextAvailableAppointment: 'Next possible appointment',
    withinNextThreeDays: 'Within the next three days',
    duration: 'Duration',
    date: 'Date',
    time: 'Time',
    anytime: 'Anytime',
    beforeEightAM: 'Before 8 a.m.',
    afterSixPM: 'After 6 p.m.',
    videoCall: 'Video call',
    clearAllFilters: 'Clear all filters',
    who: 'Who',
    when: 'When',
    why: 'Why',
    noAppointmentsYet: 'No appointments yet',
    nextAppointmentOn: 'Next appointment on',
    noAppointmentsAvailable: 'No appointments available',
    create: 'Create',
    appointment: 'Appointment',
    reminder: 'Reminder',
    conjunctions: {
      or: 'or',
      and: 'and'
    },
    sizes: {
      'verySmall': 'Very small',
      'small': 'Small',
      'medium': 'Medium',
      'large': 'Large',
      'veryLarge': 'Very large',
    },
    placeholder: {
      occupationInput: 'Specialty or Name',
      occupationExamples: "e.g. Internal medicine, General surgeon, Psychologist ...",
      specializationExamples: "e.g. Cardiology, Endocrinology ...",
      place: 'City or Zip',
      clickToSelectBirthDate: 'Click to select birth date',
      dateFormat: {
        day: 'DD',
        month: 'MM',
        year: 'YYYY',
      }
    },
    interaction: {
      yes: 'Yes',
      no: 'No',
      alreadyRegistered: 'Already have an account?',
      forgotPassword: 'Forgot Password?',
      noAccountYet: 'No account?',
      createAccountNow: 'Create one now',
      enterVerificationCode: 'Enter the verification code sent to your email address',
      didntReceiveCode: 'Didn\'t get the code?',
      waitForCodeWithTimer: 'Wait {timer} to resend the code',
      resendCode: 'Resend Code',
      next: 'Next',
      previous: 'Previous',
      OK: 'OK',
      save: 'Save',
      close: 'Close',
      cancel: 'Cancel',
      showAll: 'Show all',
      showLess: 'Show less',
      remove: 'Remove'
    },
    messages: {
      appointmentConfirmed: 'We confirmed your appointment!',
      youWillReceiveEmail: 'You will receive a confirmation e-mail in a moment.',
      noResults: 'The search returned no results matching your criteria.',
      searching: 'Searching...',
      mustNotBeEmpty: '{name} must not be empty.',
      appointmentDurationErrorMessage: '{name} must be a multiple of 5 minutes up to 420 minutes.',
      fillInTheFormCompletely: 'Please fill in the form completely',
      openingHoursMissing: 'Opening hours are missing',
      successfullySavedChanges: 'Successfully saved changes',
      failedToSaveChanges: 'Failed to save changes',
      failedToFetchAvailability: 'Failed to fetch availabilities',
      appointmentFormatDiffersFromDefault: 'The chosen format differs from the default one of the selected appointment type',
      removingOccupationWillRemoveFollowingSpecializations: 'Removing the occupation <b>{occupation}</b> will also remove the following specializations'
    },
    notifications: {
      error: 'Error',
      signOutSuccess: 'Successfully signed out',
      signInSuccess: 'Successfully signed in',
      emailNotValid: 'Email address is not valid',
      emailsNotMatching: 'Email addresses do not match',
      passwordNotValid: 'Password is not valid',
      passwordsNotMatching: 'Passwords do not match',
      phoneNumberNotValid: 'Phone number is not valid',
      signUpFailed: 'Failed to sign up',
      usernameNotValid: 'Username is not valid',
      confirmSignUpFailed: 'Failed to confirm sign up',
      codeNotValid: 'Code is not valid',
      supportedImageFormats: 'Supported file formats are jpg, png, gif or webp',
      isInvalid: '{name} is not valid'
    },
    dialogs: {
      removingOccupation: 'Removing Occupation'
    },
    constants: {
      DAYS_SUNDAY_FIRST: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
      DAYS: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"],
      MONTHS: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
    }
  },
  'de-DE': {
    account: 'Konto',
    profile: 'Profil',
    calendar: 'Kalender',
    appointmentType: 'Besuchsgrund | Besuchsgründe',
    personalData: 'Persönliche Daten',
    accountInformation: 'Kontodaten',
    userID: 'Benutzer-ID',
    accountStatus: 'Kontostatus',
    salutation: 'Anrede',
    mr: 'Herr',
    mrs: 'Frau',
    na: 'keine Angabe',
    title: 'Titel',
    lastName: 'Nachname',
    firstName: 'Vorname',
    editOpeningHours: 'Öffnungszeiten bearbeiten',
    name: 'Name',
    usePersonalPhoneNumber: 'Persönliche Telefonnummer übernehmen',
    usePersonalEmail: 'Persönliche Email Adresse übernehmen',
    occupation_eventually_plural: 'Fachrichtung(en)',
    specialization_eventually_plural: 'Schwerpunkt(e) / Weiterbildung(en)',
    country: 'Land',
    city: 'Stadt',
    streetAndHouseNumber: 'Straße und Hausnummer',
    zipCode: 'PLZ',
    addNewLocation: 'Neuen Standort hinzufügen',
    employee: 'MitarbeiterIn | MitarbeiterInnen',
    addEmployee: 'MitarbeiterIn hinzufügen',
    newEmployee: 'Neue(r) MitarbeiterIn',
    editEmployee: 'MitarbeiterIn bearbeiten',
    deleteEmployee: 'MitarbeiterIn löschen',
    noEmployees: 'Keine MitarbeiterInnen eingetragen',
    employeeHasOwnAccess: 'Eigenen Zugang einrichten (E-Mail Verifizierung notwendig)',
    selectableByCustomers: 'Kann von Kunden gebucht werden',
    appearsIndividuallyInSearchResults: 'Wird in den Suchergebnissen individuell angezeigt',
    defineIndividualWorkingHours: 'Individuelle Buchungszeiten definieren',
    doesTheEmployeeWorkAtAnotherLocation: 'Arbeitet diese(r) MitarbeiterIn auch an einem anderen Standort?',
    displayName: 'Anzeigename',
    saveChanges: 'Speichern',
    search: 'Suchen',
    signIn: 'Anmelden',
    signUp: 'Registrieren',
    signOut: 'Abmelden',
    email: 'Email Adresse',
    repeatEmail: 'Email Adresse wiederholen',
    password: 'Passwort',
    passwordRepeat: 'Passwort wiederholen',
    phoneNumber: 'Telefonnummer',
    rememberMe: 'Benutzerdaten merken',
    confirmationCode: 'Bestätigungscode',
    confirm: 'Bestätigen',
    sendCode: 'Code senden',
    backToSignIn: 'Zurück zur Anmeldung',
    confirmSignIn: {
      header: 'Anmeldung bestätigen',
      explanation: "Bitte den Bestätigungscode eingeben, den wir Ihnen gesendet haben.",
    },
    confirmSignUp: {
      header: "Registrierung bestätigen",
      explanation: "Bitte den Bestätigungscode eingeben, den wir Ihnen gesendet haben.",
    },
    forgotPassword: {
      header: "Passwort zurücksetzen",
      explanation: "Geben Sie bitte Ihre Email Adresse ein. Wir werden Ihnen einen Verifizierungscode senden, mit dem Sie Ihr Password zurücksetzen können.",
      changePassword: "Passwort ändern"
    },
    code: "Code",
    appointmentTypeNamePlaceholder: 'Besuchsgrund',
    appointmentDuration: 'Termindauer',
    appointmentCanBeBooked: 'Dieser Termin kann nur in den folgenden Zeiten gebucht werden',
    locationFilter: 'Standort Filter',
    price: 'Preis',
    eventColor: 'Terminfarbe',
    eventColorTooltip: 'Optional: personalisierte Farbkodierung des Termins in der Kalenderansicht',
    editAppointmentTypeDetails: 'Besuchsgrund bearbeiten',
    appointmentFormat: 'Terminformat',
    onSite: 'vor Ort',
    video: 'Video',
    filterRules: 'Filterregeln für zeitliche Beschränkungen',
    removeAllFilters: 'Alle Filter löschen',
    minutes: 'Minuten',
    show: 'Einblenden',
    hide: 'Ausblenden',
    all: 'Alle',
    location: 'Standort | Standorte',
    selected: 'ausgewählt',
    today: 'Heute',
    openingHours: 'Öffnungszeiten',
    add: 'Hinzufügen',
    closed: 'Geschlossen',
    showWeekend: 'Wochenende einblenden',
    calendarSettings: 'Kalendereinstellungen',
    cellSize: 'Zellengröße',
    editAbsence: 'Abwesenheiten bearbeiten',
    startHour: 'Von',
    endHour: 'Bis',
    appointmentTypeFilter: 'Besuchsgrund filtern',
    appointmentTypeFilterPlaceholder: 'Besuchsgründe',
    alwaysValid: 'Immer gültig',
    validBetween: 'Gültig zwischen',
    validFrom: 'Startdatum',
    validUntil: 'Enddatum',
    appointmentDurationTooltip: 'Dauer von den Terminslots innerhalb dieser Öffnungszeiten. Die Dauer muss ein Vielfaches von 5 Minuten sein.',
    appointmentTypeFilterTooltip: 'Spezifische Besuchsgründe hinzufügen, die Sie während dieser Öffnungszeiten akzeptieren. Lassen Sie dieses Feld leer, wenn Sie die Besuchsgründe nicht filtern möchten.',
    defineValidityRangeTooltip: 'Gültigkeitszeitraum für diese Öffnungszeiten festlegen.',
    removeOpeningHoursTooltip: 'Diese Öffnungszeiten löschen.',
    newOpeningHoursSlot: 'Neue Öffnungszeiten hinzufügen',
    appointmentDetails: 'Termindetails',
    birthDate: 'Geburtsdatum',
    messageArea: 'Nachricht',
    optionalMessageArea: 'Nachricht (optional)',
    confirmAppointment: 'Termin bestätigen',
    uploadNewProfilePicture: 'Neues Profilbild hochladen',
    removeProfilePicture: 'Profilbild löschen',
    currentTimeZone: 'Aktuelle Zeitzone',
    selectAppointmentType: 'Wählen Sie bitte Ihren Besuchsgrund aus',
    selectedAppointment: 'Ausgewählter Termin',
    appointmentReason: 'Besuchsgrund',
    insuranceType: 'Krankenversicherung',
    statutoryHealthInsurance: 'Gesetzlich versichert',
    privateHealthInsurance: 'Privat versichert',
    acceptedInsuranceTypes: 'Akzeptierte Krankenversicherungsarten',
    allInsuranceTypes: 'Alle Versicherungsarten',
    statutoryHealthInsuranceOnly: 'Nur gesetzliche Versicherung',
    privateHealthInsuranceOnly: 'Nur Privatversicherung',
    dateAndTime: 'Datum und Uhrzeit',
    nextAvailableAppointment: 'Nächstmöglicher Termin',
    withinNextThreeDays: 'Innerhalb der nächsten drei Tage',
    duration: 'Dauer',
    date: 'Datum',
    time: 'Uhrzeit',
    anytime: 'Jederzeit',
    beforeEightAM: 'Vor 8 Uhr',
    afterSixPM: 'Nach 18 Uhr',
    videoCall: 'Videosprechstunde',
    clearAllFilters: 'Alle Filter zurücksetzen',
    who: 'Wer',
    when: 'Wann',
    why: 'Warum',
    noAppointmentsYet: 'Noch keine Termine',
    nextAppointmentOn: 'Nächster Termin am',
    noAppointmentsAvailable: 'Keine Termine verfügbar',
    create: 'Erstellen',
    appointment: 'Termin',
    reminder: 'Erinnerung',
    conjunctions: {
      or: 'oder',
      and: 'und'
    },
    sizes: {
      'verySmall': 'Sehr klein',
      'small': 'Klein',
      'medium': 'Mittel',
      'large': 'Groß',
      'veryLarge': 'Sehr groß',
    },
    placeholder: {
      occupationInput: 'Fachgebiet oder Name',
      occupationExamples: "z.B. Internistin, Allgemeinchirurg, Psychologe ...",
      specializationExamples: "z.B. Kardiologie, Endokrinologie ...",
      place: 'Stadt oder PLZ',
      clickToSelectBirthDate: 'Klicken, um Geburtsdatum einzugeben',
      dateFormat: {
        day: 'TT',
        month: 'MM',
        year: 'JJJJ',
      }
    },
    interaction: {
      yes: 'Ja',
      no: 'Nein',
      alreadyRegistered: 'Du hast bereits ein Konto?',
      forgotPassword: 'Passwort vergessen?',
      noAccountYet: 'Noch kein Konto?',
      createAccountNow: 'Jetzt erstellen',
      enterVerificationCode: 'Bitte den Bestätigungscode eingeben, den du per Email erhalten hast',
      didntReceiveCode: 'Den Code nicht bekommen?',
      waitForCodeWithTimer: 'Bitte {timer} warten, um den Code neu anzufordern',
      resendCode: 'Nochmal anfordern',
      next: 'Weiter',
      previous: 'Zurück',
      OK: 'OK',
      save: 'Speichern',
      close: 'Schließen',
      cancel: 'Abbrechen',
      showAll: 'Alle anzeigen',
      showLess: 'Weniger anzeigen',
      remove: 'Löschen'
    },
    messages: {
      appointmentConfirmed: 'Wir haben deinen Termin bestätigt!',
      youWillReceiveEmail: 'Wir senden dir in Kürze die Bestätigung auch per Email.',
      noResults: 'Wir haben für Ihre Suche leider nichts gefunden.',
      searching: 'Wir suchen...',
      mustNotBeEmpty: '{name} darf nicht leer sein.',
      appointmentDurationErrorMessage: '{name} muss ein Veilfaches von 5 Minuten sein und darf 420 Minuten nicht überschreiten.',
      fillInTheFormCompletely: 'Bitte das Formular vollständig ausfüllen',
      openingHoursMissing: 'Die Öffnungszeiten sind nicht vollständig',
      successfullySavedChanges: 'Änderungen erfolgreich gespeichert',
      failedToSaveChanges: 'Änderungen speichern fehlgeschlagen',
      failedToFetchAvailability: 'Verfügbarkeitsabfrage fehlgeschlagen',
      appointmentFormatDiffersFromDefault: 'Terminformat weicht von dem Standardformat des eingegebenen Besuchsgrunds ab',
      removingOccupationWillRemoveFollowingSpecializations: 'Wenn Sie den Beruf <b>{occupation}</b> löschen, werden die folgenden Schwerpunkte nicht mehr verfügbar'
    },
    notifications: {
      error: 'Fehler',
      signOutSuccess: 'Erfolgreich abgemeldet',
      signInSuccess: 'Erfolgreich angemeldet',
      emailNotValid: 'Email Addresse ist nicht gültig',
      emailsNotMatching: 'Email Adressen passen nicht zusammen',
      passwordNotValid: 'Passwort ist nicht gültig',
      passwordsNotMatching: 'Passwörter sind nicht identisch',
      phoneNumberNotValid: 'Telefonnummer ist nicht gültig',
      signUpFailed: 'Registrierung fehlgeschlagen',
      usernameNotValid: 'Benutzername nicht gültig',
      confirmSignUpFailed: 'Registrierung konnte nicht bestätigt werden',
      codeNotValid: 'Code ist nicht gültig',
      supportedImageFormats: 'Unterstützte Dateiformate sind jpg, png, gif oder webp',
      isInvalid: '{name} ist nicht gültig'
    },
    dialogs: {
      removingOccupation: 'Beruf löschen'
    },
    constants: {
      DAYS_SUNDAY_FIRST: ["Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"],
      DAYS: ["Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag", "Sonntag"],
      MONTHS: ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"]
    }
  },
  'tr-TR': {
    account: 'Hesap',
    profile: 'Profil',
    calendar: 'Takvim',
    appointmentType: 'Randevu Tipi | Randevu Tipleri',
    personalData: 'Kişisel Bilgiler',
    accountInformation: 'Hesap Bilgileri',
    userID: 'Kullanıcı Kimliği',
    accountStatus: 'Hesap Durumu',
    salutation: 'Cinsiyet',
    mr: 'Bay',
    mrs: 'Bayan',
    na: 'Bildirmek istemiyorum',
    title: 'Unvan',
    lastName: 'Soyad',
    firstName: 'Ad',
    editOpeningHours: 'Çalışma saatlerini düzenle',
    name: 'İsim',
    usePersonalPhoneNumber: 'Kişisel telefonu kullan',
    usePersonalEmail: 'Kişisel e-postayı kullan',
    occupation_eventually_plural: 'Meslek(ler)',
    specialization_eventually_plural: 'Uzmanlık Alan(lar)ı',
    country: 'Ülke',
    city: 'Şehir',
    streetAndHouseNumber: 'Adres',
    zipCode: 'Posta Kodu',
    addNewLocation: 'Yeni konum ekle',
    employee: 'Çalışan | Çalışanlar',
    addEmployee: 'Çalışan ekle',
    newEmployee: 'Yeni çalışan',
    editEmployee: 'Çalışanı düzenle',
    deleteEmployee: 'Çalışanı sil',
    noEmployees: 'Tanımlı çalışan yok',
    employeeHasOwnAccess: 'Kendi hesap erişimini oluştur (E-mail adres onayı gerektirir)',
    selectableByCustomers: 'Müşteriler tarafından seçilebilir yap',
    appearsIndividuallyInSearchResults: 'Bireysel olarak arama sonuçlarında göster',
    defineIndividualWorkingHours: 'Bireysel çalışma saatleri tanımla',
    doesTheEmployeeWorkAtAnotherLocation: 'Bu çalışan başka bir konumda da çalışıyor mu?',
    displayName: 'Gösterilen İsim',
    saveChanges: 'Değişiklikleri kaydet',
    search: 'Ara',
    signIn: 'Oturum aç',
    signUp: 'Kaydol',
    signOut: 'Oturumu kapat',
    email: 'E-posta adresi',
    repeatEmail: 'E-posta adresinizi tekrarlayın',
    password: 'Şifre',
    passwordRepeat: 'Şifrenizi tekrarlayın',
    phoneNumber: 'Telefon numarası',
    rememberMe: 'Beni hatırla',
    confirmationCode: 'Onay kodu',
    confirm: 'Onayla',
    sendCode: 'Onay kodu gönder',
    backToSignIn: 'Oturum açmaya geri dön',
    confirmSignIn: {
      header: 'Oturum açmayı onayla',
      explanation: "Lütfen size gönderdiğimiz onay kodunu girin",
    },
    confirmSignUp: {
      header: "Kaydı onayla",
      explanation: "Lütfen size gönderdiğimiz onay kodunu girin",
    },
    forgotPassword: {
      header: "Şifreyi sıfırla",
      explanation: "Lütfen e.posta adresinizi giriniz. Size göndereceğimiz kod ile şifrenizi sıfırlayabilirsiniz.",
      changePassword: "Şifreyi değiştir"
    },
    code: "Kod",
    appointmentTypeNamePlaceholder: 'Randevu tipinin adı',
    appointmentDuration: 'Randevu Süresi',
    appointmentCanBeBooked: 'Bu randevu sadece aşağıda belirtilen zamanlarda alınabilir',
    locationFilter: 'Konum Filtresi',
    price: 'Fiyat',
    eventColor: 'Randevu Rengi',
    eventColorTooltip: 'Randevular takvimde isteğe bağlı olarak randevu tipine göre renklendirilebilir.',
    editAppointmentTypeDetails: 'Randevu tipi detaylarını düzenle',
    appointmentFormat: 'Randevu formatı',
    onSite: 'Yerinde',
    video: 'Video',
    filterRules: 'Zaman Filtresi Kuralları',
    removeAllFilters: 'Tüm filtreleri kaldır',
    minutes: 'dakika',
    show: 'Göster',
    hide: 'Gizle',
    all: 'Tüm',
    location: 'konum | konumlar',
    selected: 'seçili',
    today: 'Bugün',
    openingHours: 'Çalışma Saatleri',
    add: 'Ekle',
    closed: 'Kapalı',
    showWeekend: 'Hafta sonunu göster',
    calendarSettings: 'Takvim ayarları',
    cellSize: 'Hücre boyutu',
    editAbsence: 'Tatil günlerini düzenle',
    startHour: 'Başlangıç Saati',
    endHour: 'Bitiş Saati',
    appointmentTypeFilter: 'Randevu Tipi Filtresi',
    appointmentTypeFilterPlaceholder: 'Randevu tipleri',
    alwaysValid: 'Her zaman geçerli',
    validBetween: 'Tarihler arasında geçerli',
    validFrom: 'Başlangıç tarihi',
    validUntil: 'Bitiş tarihi',
    appointmentDurationTooltip: 'Bu açılış saat aralığı kaçar dakikalık randevu aralıklarına bölünmeli? Randevu aralığı uzunluğu 5 dakikanın katları olmak zorundadır.',
    appointmentTypeFilterTooltip: 'Bu açılış saatleri arasında hangi randevu tiplerini kabul edeceğinizi sınırlandırabilirsiniz. Filtrelemek istemiyorsanız bu alanı boş bırakın.',
    defineValidityRangeTooltip: 'Bu açılış saat aralığının hangi tarihler arasında geçerli olacağını sınırlandırabilirsiniz.',
    removeOpeningHoursTooltip: 'Bu açılış saat aralığını sil.',
    newOpeningHoursSlot: 'Yeni açılış saat aralığı ekle',
    appointmentDetails: 'Randevu detayları',
    birthDate: 'Doğum Tarihi',
    messageArea: 'Notlar',
    optionalMessageArea: 'Notlar (isteğe bağlı)',
    confirmAppointment: 'Randevumu onayla',
    uploadNewProfilePicture: 'Yeni profil fotoğrafı yükle',
    removeProfilePicture: 'Profil fotoğrafını sil',
    currentTimeZone: 'Saat dilimi',
    selectAppointmentType: 'Randevu nedeninizi seçiniz',
    selectedAppointment: 'Seçili randevu',
    appointmentReason: 'Randevu nedeni',
    insuranceType: 'Sağlık sigortası',
    statutoryHealthInsurance: 'Devlet sağlık sigortası',
    privateHealthInsurance: 'Özel sağlık sigortası',
    acceptedInsuranceTypes: 'Kabul edilen sağlık sigortaları',
    allInsuranceTypes: 'Tüm sağlık sigortaları',
    statutoryHealthInsuranceOnly: 'Sadece devlet sağlık sigortası',
    privateHealthInsuranceOnly: 'Sadece özel sağlık sigortası',
    dateAndTime: 'Saat ve tarih',
    nextAvailableAppointment: 'En yakın randevu tarihi',
    withinNextThreeDays: 'Üç gün içinde',
    duration: 'Süre',
    date: 'Tarih',
    time: 'Saat',
    anytime: 'Herhangi bir zaman',
    beforeEightAM: '8\'den önce',
    afterSixPM: '18\'den sonra',
    videoCall: 'Video konferans',
    clearAllFilters: 'Tüm filtreleri sıfırla',
    who: 'Kim',
    when: 'Ne zaman',
    why: 'Neden',
    noAppointmentsYet: 'Henüz randevunuz yok',
    nextAppointmentOn: 'En yakın randevu tarihi',
    noAppointmentsAvailable: 'Tüm randevular dolu',
    create: 'Oluştur',
    appointment: 'Randevu',
    reminder: 'Hatırlatma',
    conjunctions: {
      or: 'veya',
      and: 've'
    },
    sizes: {
      'verySmall': 'En küçük',
      'small': 'Küçük',
      'medium': 'Orta',
      'large': 'Büyük',
      'veryLarge': 'En büyük',
    },
    placeholder: {
      occupationInput: 'Uzmanlık alanı veya İsim',
      occupationExamples: "Örneğin İç hastalıkları uzmanı, Genel cerrah, Psikolog ...",
      specializationExamples: "Örneğin Kardiyoloji, Endokrinoloji ...",
      place: 'Şehir veya Posta Kodu',
      clickToSelectBirthDate: 'Doğum tarihi seçmek için tıklayınız',
      dateFormat: {
        day: 'GG',
        month: 'AA',
        year: 'YYYY',
      }
    },
    interaction: {
      yes: 'Evet',
      no: 'Hayır',
      alreadyRegistered: 'Zaten hesabınız var mı?',
      forgotPassword: 'Şifremi unuttum',
      noAccountYet: 'Hesabınız yok mu?',
      createAccountNow: 'Şimdi bir tane oluşturun',
      enterVerificationCode: 'Lütfen size gönderilen onay kodunu giriniz',
      didntReceiveCode: 'Kod size ulaşmadı mı?',
      waitForCodeWithTimer: 'Kodu göndermek için lütfen {timer} bekleyin',
      resendCode: 'Tekrar gönder',
      next: 'İleri',
      previous: 'Geri',
      OK: 'Tamam',
      save: 'Kaydet',
      close: 'Kapat',
      cancel: 'İptal',
      showAll: 'Tümünü göster',
      showLess: 'Daha az göster',
      remove: 'Sil'
    },
    messages: {
      appointmentConfirmed: 'Randevunuz başarıyla onaylandı!',
      youWillReceiveEmail: 'Randevu onay e-postanız birkaç saniye içinde size ulaşacaktır.',
      noResults: 'Aradığınız kriterlere uygun sonuç bulunamadı.',
      searching: 'Aranıyor...',
      mustNotBeEmpty: '{name} boş olamaz.',
      appointmentDurationErrorMessage: '{name} maksimum 420 dakika olmak üzere 5 dakikanın tam katı olmak zorundadır.',
      fillInTheFormCompletely: 'Lütfen formu eksiksiz olarak doldurunuz',
      openingHoursMissing: 'Çalışma saatleri tanımlanmadı',
      successfullySavedChanges: 'Değişiklikler başarıyla kaydedildi',
      failedToSaveChanges: 'Değişiklikler kaydedilemedi',
      failedToFetchAvailability: 'Müsaitlik durumu sorgusunda hata oluştu',
      appointmentFormatDiffersFromDefault: 'Randevu formatı seçilen randevu tipi için öngörülen formattan farklı',
      removingOccupationWillRemoveFollowingSpecializations: '<b>{occupation}</b> mesleğini silmeniz halinde aşağıdaki uzmanlık alanları da otomatik olarak silinecektir'
    },
    notifications: {
      error: 'Hata',
      signOutSuccess: 'Başarıyla oturum kapatıldı',
      signInSuccess: 'Başarıyla oturum açıldı',
      emailNotValid: 'E-posta adresi geçersiz',
      emailsNotMatching: 'E-posta adresleri uyuşmuyor',
      passwordNotValid: 'Şifre geçersiz',
      passwordsNotMatching: 'Şifreler uyuşmuyor',
      phoneNumberNotValid: 'Telefon numarası geçersiz',
      signUpFailed: 'Kaydolma hatası',
      usernameNotValid: 'Kullanıcı adı geçersiz',
      confirmSignUpFailed: 'Kayıt olma işlemı onaylanamadı',
      codeNotValid: 'Kod geçersiz',
      supportedImageFormats: 'Desteklenen resim formatları: jpg, png, gif veya webp',
      isInvalid: '{name} geçersiz'
    },
    dialogs: {
      removingOccupation: 'Mesleği sil'
    },
    constants: {
      DAYS_SUNDAY_FIRST: ["Pazar", "Pazartesi", "Salı", "Çarşamba", "Perşembe", "Cuma", "Cumartesi"],
      DAYS: ["Pazartesi", "Salı", "Çarşamba", "Perşembe", "Cuma", "Cumartesi", "Pazar"],
      MONTHS: ["Ocak", "Şubat", "Mart", "Nisan", "Mayıs", "Haziran", "Temmuz", "Ağustos", "Eylül", "Ekim", "Kasım", "Aralık"]
    }
  }
}

Vue.use(VueI18n);

export default new VueI18n({
  locale: 'de-DE',
  messages,
  pluralizationRules: {
    /**
     * @param choice {number} a choice index given by the input to $tc: `$tc('path.to.rule', choiceIndex)`
     * @param choicesLength {number} an overall amount of available choices
     * @returns a final choice index to select plural word by
     */
    'tr-TR': function(choice, _choicesLength) {
      // this === VueI18n instance, so the locale property also exists here

      const isnum = /^\d+$/.test(choice.toString());

      if (choice === 0 || isnum) {
        return 0;
      }

      return 1;
    }
  }
})
